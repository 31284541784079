<template>
  <div class="card card-bleed border-bottom border-bottom-md-0 shadow-light-lg">
    <div id="sidenavCollapse" class="collapse d-md-block">
      <div class="card-body">
        <h6 class="font-weight-bold text-uppercase mb-3">
          Organization
        </h6>
        <ul class="card-list list text-gray-700 mb-6">
          <li class="list-item" v-if="hasPermission('view-organization')">
            <router-link
              class="list-link text-reset"
              :to="{ name: 'organization' }"
            >
              Organization
            </router-link>
          </li>
          <li class="list-item" v-if="hasPermission('view-subscription')">
            <router-link
              class="list-link text-reset"
              :to="{ name: 'subscription' }"
            >
              Subscription
            </router-link>
          </li>
        </ul>
        <h6
          class="font-weight-bold text-uppercase mb-3"
          v-if="viewTeamAndUsers"
        >
          Team & Users
        </h6>
        <ul class="card-list list text-gray-700 mb-6">
          <li class="list-item" v-if="hasPermission('view-users')">
            <router-link
              class="list-link text-reset"
              :to="{ name: 'organization.users' }"
            >
              Users
            </router-link>
          </li>
          <li class="list-item" v-if="hasPermission('view-roles')">
            <router-link
              class="list-link text-reset"
              :to="{ name: 'organization.roles' }"
            >
              Roles and permissions
            </router-link>
          </li>
          <li class="list-item" v-if="hasPermission('view-stores')">
            <router-link
              class="list-link text-reset"
              :to="{ name: 'organization.stores' }"
            >
              Stores
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Permission from "@/utils/permission";
export default {
  name: "OrganizationNav",
  extends: Permission,
  computed: {
    viewTeamAndUsers() {
      return (
        this.hasPermission("view-users") ||
        this.hasPermission("view-roles") ||
        this.hasPermission("view-stores")
      );
    },
    viewEmailMenus() {
      return (
        this.hasPermission("view-email-config") ||
        this.hasPermission("view-email-templates")
      );
    },
  },
};
</script>

<style scoped>
.text-reset:hover,
.list-item .active {
  color: #2db6fc !important;
}
</style>
