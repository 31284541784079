<template>
  <component :is="layout">
    <div class="row fadeInUp" role="main">
      <div class="col-12 col-md-3">
        <OrganizationNav />
      </div>
      <div class="col-12 col-md-9">
        <div class="card border-0 shadow-sm">
          <div class="card-header">
            <h4>
              Sync all orders plugin
              <button
                class="btn btn-sm float-right btn-outline-secondary"
                @click="downloadPlugin"
              >
                Download plugin
              </button>
            </h4>
          </div>
          <div class="card-body">
            <!-- Set image thumbnail for youtube video -->
            <div
              id="thumbnail"
              onclick="this.nextElementSibling.style.display='block'; this.style.display='none'"
            >
              <img
                src="@/assets/youtube-logo.svg"
                style="cursor:pointer; height: 100px;"
              />
            </div>
            <div style="display:none">
              <iframe
                width="100%"
                height="450"
                src="https://www.youtube.com/embed/ZeUkcUVFRm8?autoplay=1"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </div>
            <a
              href="https://automation.app/helpdesk/order-import-plugin-bulk-import-woocommerce-orders-to-automation-app"
              target="_blank"
              >Read full instructions</a
            >
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import jsonAPI from "@/api";
import OrganizationNav from "@/components/Nav/OrganizationNav.vue";
import Default from "@/layouts/default.vue";

export default {
  components: {
    OrganizationNav,
    Default
  },
  data: function() {
    return {
      layout: "Default",
      loading: false,
      step_status: {
        key: "install_plugin",
        status: true,
      },
    };
  },
  methods: {
    async downloadPlugin() {
      await jsonAPI({
        url: "/download",
        method: "GET",
        responseType: "blob",
      })
        .then((resp) => {
          var fileURL = window.URL.createObjectURL(
            new Blob([resp.data], { type: "application/octet-stream" })
          );
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", "automation-app-bulk-import.zip");
          document.body.appendChild(fileLink);
          fileLink.click();

          this.$store.dispatch("CHECK_ONBOARDING_STEP", this.step_status);
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
  },
};
</script>

<style scoped>
#thumbnail {
  height: 450px;
  text-align: center;
  background: url("/plugin-thumbnail.png");
  background-size: cover;
  padding-top: 175px;
}
</style>
